import React from 'react';
import { graphql } from 'gatsby';
import '../styles/content.css';

import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';

import { remark } from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';

function Page({
  data: {
    markdownRemark: { frontmatter: page }
  },
}) {
  return (
    <React.Fragment>
      <SEO pageTitle="Kokroze" />
      <Breadcrumbs paths={[{ name: page.name, to: page.url }]} />
      <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
        {page.name}
      </h1>

      <div
        className="mb-6 page-content"
        dangerouslySetInnerHTML={{ __html: remark().use(recommended).use(remarkHtml).processSync(page.content).toString() }}
      />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query PageQuery($url: String!) {
    markdownRemark(frontmatter: { url: { eq: $url}}) {
      frontmatter {
        url
        name
        content
      }
    }
  }
`;

export default Page;
